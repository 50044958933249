import React, { FC, useEffect, useState, useCallback } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { sw, fs, paddingHorizontal, paddingVertical } from '@wowmaking/ui/src/utils';
import SplashScreen from 'react-native-splash-screen';

import Analytics from 'analytics';
import { LIGHT_TAN, WHITE, DARK_BLUE_GREY_2 } from 'constants/colors';
import { useAppSelector, useAppDispatch } from 'store';
import { initApp } from 'store/app/actions';
import { showBackgroundPlacement } from 'store/unlock-content/actions';
import DisconnectModal from 'reusable/disconnect-modal';

import Splash from './components/Splash';

const LoadingScreen: FC = () => {
  const dispatch = useAppDispatch();
  const isInternetConnected = useAppSelector(state => state.app.isInternetConnected);
  const [isFirstLoadingDid, setFirstLoadingDid] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const handleCatch = (err: any) => {
    console.warn('[Loading Screen] handleCatch', err);
    Analytics.trackEvent('Loading', 'Error', {
      message: String(err?.message || err),
    });
    setError(err);
  };

  const handleLoadedApp = useCallback(async () => {
    try {
      await dispatch(initApp());
      Analytics.trackEvent('init_app', 'success');
      setFirstLoadingDid(true);
      dispatch(showBackgroundPlacement());
    } catch (err: any) {
      return handleCatch(err);
    }
  }, [dispatch]);

  const handleErrorModal = async () => {
    Analytics.trackEvent('Disconnect_Retry', 'Click');

    try {
      await dispatch(initApp());
      return setError(null);
    } catch (err) {
      return handleCatch(err);
    }
  };

  useEffect(() => {
    SplashScreen.hide();
    handleLoadedApp();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFirstLoadingDid && !isInternetConnected) {
      setFirstLoadingDid(true);
      handleLoadedApp();
    }
  }, [isInternetConnected, isFirstLoadingDid, handleLoadedApp]);

  return error ? (
    <View style={styles.problems}>
      <DisconnectModal onPress={handleErrorModal} />
    </View>
  ) : (
    <Splash />
  );
};

export default LoadingScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bg: {
    ...StyleSheet.absoluteFillObject,
  },
  problems: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: paddingHorizontal(20),
  },
  title: {
    color: WHITE,
    fontSize: Platform.select({
      ios: fs(40),
      android: fs(36),
      web: fs(45),
    }),
    fontWeight: 'bold',
  },
  desc: {
    color: LIGHT_TAN,
    fontSize: Platform.select({
      ios: fs(18),
      android: fs(17),
      web: fs(18),
    }),
    marginTop: paddingVertical(10),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  oval: {
    position: 'absolute',
    width: sw(271),
    height: sw(271),
    borderRadius: sw(261),
    backgroundColor: DARK_BLUE_GREY_2,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
