import { ImageSourcePropType } from 'react-native';

import { BROWSERS_TYPES } from 'constants/pwa';
import { windowHeight } from 'constants/sizes';
// Soft currencies
import COINS from 'components/advisors/soft-currency/icon/coins.png';
import GEM_BLUE from 'components/advisors/soft-currency/icon/gem-blue.png';
import GEM_VIOLET from 'components/advisors/soft-currency/icon/gem-violet.png';

import IOS_IMG from './img/ios.png';
import IOS_OTHER_IMG from './img/ios-other.png';
import ANDROID_CHROME_IMG from './img/android-chrome.png';
import ANDROID_SAMSUNG_IMG from './img/android-samsung.png';
import ANDROID_OTHER_IMG from './img/android-other.png';
import PLUS_ICON_V2 from './img/ic-plus.png';
import MENU_ICON_V2 from './img/ic-menu.png';
import DOTS_ICON_V2 from './img/ic-dots.png';
import SHARE_ICON_V2 from './img/ic-share.png';
import ADD_ICON_V2 from './img/ic-add.png';

export type ImagesPack = {
  [key: string]: ImageSourcePropType;
};

export const ICONS_MAP: ImagesPack = {
  SHARE_V2_ICON: SHARE_ICON_V2,
  ADD_V2_ICON: ADD_ICON_V2,
  DOTS_V2_ICON: DOTS_ICON_V2,
  PLUS_V2_ICON: PLUS_ICON_V2,
  MENU_V2_ICON: MENU_ICON_V2,
  COINS_ICON: COINS,
  GEMS_BLUE_ICON: GEM_BLUE,
  GEMS_VIOLET_ICON: GEM_VIOLET,
};

export const IS_SMALL_SCREEN = windowHeight < 600;

export const INSTRUCTION_IMAGES: { [key in BROWSERS_TYPES]: ImageSourcePropType } = {
  [BROWSERS_TYPES.IOS_SAFARI]: IOS_IMG,
  [BROWSERS_TYPES.IOS_CHROME]: IOS_IMG,
  [BROWSERS_TYPES.IOS_OTHERS]: IOS_OTHER_IMG,
  [BROWSERS_TYPES.ANDROID_SAMSUNG]: ANDROID_SAMSUNG_IMG,
  [BROWSERS_TYPES.ANDROID_CHROME]: ANDROID_CHROME_IMG,
  [BROWSERS_TYPES.ANDROID_OTHERS]: ANDROID_OTHER_IMG,
};

export const INSTRUCTION_SAFARI_GIFS: { [key: string]: ImageSourcePropType } = {
  ar: require('./img/gif/AR_Safari_popup.gif'),
  de: require('./img/gif/DE_Safari_popup.gif'),
  en: require('./img/gif/EN_Safari_popup.gif'),
  es: require('./img/gif/ES_Safari_popup.gif'),
  fr: require('./img/gif/FR_Safari_popup.gif'),
  ja: require('./img/gif/JA_Safari_popup.gif'),
  pt: require('./img/gif/PT_Safari_popup.gif'),
  default: require('./img/gif/EN_Safari_popup.gif'),
};

export const INSTRUCTION_CHROME_IOS_GIFS: { [key: string]: ImageSourcePropType } = {
  ar: require('./img/gif/AR_Chrome_popup.gif'),
  de: require('./img/gif/DE_Chrome_popup.gif'),
  en: require('./img/gif/EN_Chrome_popup.gif'),
  es: require('./img/gif/ES_Chrome_popup.gif'),
  fr: require('./img/gif/FR_Chrome_popup.gif'),
  ja: require('./img/gif/JA_Chrome_popup.gif'),
  pt: require('./img/gif/PT_Chrome_popup.gif'),
  default: require('./img/gif/EN_Chrome_popup.gif'),
};

export const INSTRUCTION_CHROME_ANDROID_GIFS: { [key: string]: ImageSourcePropType } = {
  ar: require('./img/gif/AR_Chrome_Android_popup.gif'),
  de: require('./img/gif/DE_Chrome_Android_popup.gif'),
  en: require('./img/gif/EN_Chrome_Android_popup.gif'),
  es: require('./img/gif/ES_Chrome_Android_popup.gif'),
  fr: require('./img/gif/FR_Chrome_Android_popup.gif'),
  ja: require('./img/gif/JA_Chrome_Android_popup.gif'),
  pt: require('./img/gif/PT_Chrome_Android_popup.gif'),
  default: require('./img/gif/EN_Chrome_Android_popup.gif'),
};

export const INSTRUCTION_SAMSUNG_GIFS: { [key: string]: ImageSourcePropType } = {
  ar: require('./img/gif/AR_Samsung_browser_popup.gif'),
  de: require('./img/gif/DE_Samsung_browser_popup.gif'),
  en: require('./img/gif/EN_Samsung_browser_popup.gif'),
  es: require('./img/gif/ES_Samsung_browser_popup.gif'),
  fr: require('./img/gif/FR_Samsung_browser_popup.gif'),
  ja: require('./img/gif/JA_Samsung_browser_popup.gif'),
  pt: require('./img/gif/PT_Samsung_browser_popup.gif'),
  default: require('./img/gif/EN_Samsung_browser_popup.gif'),
};
