import { Component, FC } from 'react';

export enum ADVISORS_CATEGORIES {
  LOVE = 'love',
  ACCURATE = 'accurate',
  RECOMMENDED = 'recommended',
  PREMIUM = 'premium',
}

export enum ADVISORS_CATALOG_CUSTOM_BLOCK_IDS {
  ALL = 'all',
  CHATS = 'chats',
  RANDOM = 'random',
}

// Only for Stealth mode (stealthMode: true)
export enum ADVISORS_STEALTH_CATEGORIES {
  COOKING = 'cooking',
  MATH = 'math',
  HISTORY = 'history',
  HR_ADVISORS = 'hr_advisors',
}

export enum ADVISORS_CUSTOM_STEALTH_CATEGORIES {
  ASTROLOGY = 'astrology',
}

export const ADVISORS_CATALOG_BLOCKS = {
  ...ADVISORS_CATEGORIES,
  ...ADVISORS_CATALOG_CUSTOM_BLOCK_IDS,
  ...ADVISORS_STEALTH_CATEGORIES,
  ...ADVISORS_CUSTOM_STEALTH_CATEGORIES,
};

export type ADVISORS_CATALOG_BLOCK_IDS =
  | ADVISORS_CATEGORIES
  | ADVISORS_CATALOG_CUSTOM_BLOCK_IDS
  | ADVISORS_STEALTH_CATEGORIES
  | ADVISORS_CUSTOM_STEALTH_CATEGORIES;

export type AdvisorsCatalogStealthCategoryPid = {
  [key in ADVISORS_CATALOG_BLOCK_IDS]?: number | string;
};

export type AdvisorsCatalogBlocks = {
  [key in ADVISORS_CATALOG_BLOCK_IDS]: FC<any> | typeof Component;
};
