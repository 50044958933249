import React, { FC, memo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, sw, color } from '@wowmaking/ui/src/utils';
import { ImageStyle } from 'react-native';

import { t } from 'localization';
import { useAppSelector } from 'store';
import Text from 'components/text';
import * as COLORS from 'constants/colors';
import SoftCurrencyAmount from 'components/advisors/soft-currency/soft-currency-amount';

interface Props {
  style?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ImageStyle>;
  withAdditionalText?: boolean;
}

const SoftCurrencyRate: FC<Props> = ({ style = null, iconStyle = null, withAdditionalText = false }) => {
  const softCurrencyConfig = useAppSelector(state => state.remoteConfig.advisorSoftCurrencyConfig);
  const softCurrencyShowOnAdvisorCard = useAppSelector(state => state.remoteConfig.softCurrencyShowOnAdvisorCard);

  const renderText = () => {
    return <Text style={styles.currencyText}>{t('TODAY.ASTROLOGIST.MINUTES_RATE')}</Text>;
  };

  if (!softCurrencyConfig.enabled || !softCurrencyShowOnAdvisorCard) {
    return null;
  }

  return (
    <View style={[styles.root, style]}>
      {withAdditionalText && <Text style={[styles.currencyText, styles.costText]}>{t('TODAY.ASTROLOGIST.COST_CHAT')}</Text>}
      <SoftCurrencyAmount textStyle={styles.currencyText} iconStyle={[styles.currencyIcon, iconStyle]} content={renderText()} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: paddingVertical(5),
  },
  currencyIcon: {
    width: sw(12),
    height: sw(12),
    marginLeft: paddingHorizontal(1),
    marginRight: paddingHorizontal(1),
  },
  currencyText: {
    fontSize: fs(10),
    color: color(COLORS.BEIGE, 0.5),
    fontWeight: '400',
  },
  costText: {
    marginRight: 4,
  },
});

export default memo(SoftCurrencyRate);
