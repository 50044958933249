import { lazy } from 'react';

import * as ROUTES from 'constants/routes';
import CardOfTheDay from 'screens/tarot/card-of-the-day';
import YesOrNo from 'screens/tarot/yes-or-no';

import Loading from '../loading';

const lazyRetry: typeof lazy = importer => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error) {
      for (let i = 0; i < 5; i++) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        try {
          return await importer();
        } catch (e) {
          console.log('retrying import');
        }
      }
      throw error;
    }
  };
  return lazy(retryImport);
};

const SCREENS = {
  [ROUTES.LOADING]: Loading,
  [ROUTES.MAIN]: lazyRetry(() => import('./bottom-tab-bar')),
  // here main stack screens
  [ROUTES.TAROT]: lazyRetry(() => import('../tarot')),
  [ROUTES.TAROT_CARD_OF_THE_DAY]: CardOfTheDay,
  [ROUTES.TAROT_YES_OR_NO]: YesOrNo,
  [ROUTES.TAROT_CALENDAR]: lazyRetry(() => import('../tarot/tarot-calendar')),
  [ROUTES.TAROT_LAYOUT]: lazyRetry(() => import('../tarot/tarot-layout')),
  [ROUTES.TAROT_CARDS_DESCRIPTION]: lazyRetry(() => import('../tarot/cards-description')),
  [ROUTES.DIVINATIONS]: lazyRetry(() => import('../divinations')),
  [ROUTES.DASHBOARD]: lazyRetry(() => import('../dashboard')),
  [ROUTES.HOROSCOPES]: lazyRetry(() => import('../horoscopes')),
  [ROUTES.HOROSCOPE]: lazyRetry(() => import('../horoscope-view')),
  [ROUTES.BIORHYTHM]: lazyRetry(() => import('../biorhythm')),
  [ROUTES.MAGIC_BALL]: lazyRetry(() => import('../magic-ball')),
  [ROUTES.ONBOARDING_WELCOME]: lazyRetry(() => import('../onboarding/welcome')),
  [ROUTES.ONBOARDING_DATE]: lazyRetry(() => import('../onboarding/date-of-birth')),
  [ROUTES.ONBOARDING_PLACE]: lazyRetry(() => import('../onboarding/place-of-birth')),
  [ROUTES.ONBOARDING_TIME]: lazyRetry(() => import('../onboarding/time-of-birth')),
  [ROUTES.ONBOARDING_GENDER]: lazyRetry(() => import('../onboarding/gender')),
  [ROUTES.ONBOARDING_NAME]: lazyRetry(() => import('../onboarding/name')),
  [ROUTES.ONBOARDING_PALM]: lazyRetry(() => import('../onboarding/palm')),
  [ROUTES.ONBOARDING_PALM_SCAN]: lazyRetry(() => import('../onboarding/palm/scan')),
  [ROUTES.ONBOARDING_RELATIONSHIP]: lazyRetry(() => import('../onboarding/relationship')),
  [ROUTES.ONBOARDING_LOADER]: lazyRetry(() => import('../onboarding/loader')),
  [ROUTES.COMPATIBILITY]: lazyRetry(() => import('../compatibilities/compatibility')),
  [ROUTES.COMPATIBILITY_RESULT]: lazyRetry(() => import('../compatibilities/compatibility/result')),
  [ROUTES.BIRTHCHARTS_COM8TY_ONB]: lazyRetry(() => import('../birth-charts-compatibility/onboarding')),
  [ROUTES.BIRTHCHARTS_COM8TY]: lazyRetry(() => import('../birth-charts-compatibility')),
  [ROUTES.BIRTHCHARTS_COM8TY_EDIT]: lazyRetry(() => import('../birth-charts-compatibility/edit-partner-profile')),
  [ROUTES.SETTINGS]: lazyRetry(() => import('../settings')),
  [ROUTES.PROFILE]: lazyRetry(() => import('../profile')),
  [ROUTES.EDIT_PROFILE]: lazyRetry(() => import('../profile/edit-profile')),
  [ROUTES.NAME_SETTINGS]: lazyRetry(() => import('../profile/name')),
  [ROUTES.GENDER_SETTINGS]: lazyRetry(() => import('../profile/gender')),
  [ROUTES.RELATIONSHIP_SETTINGS]: lazyRetry(() => import('../profile/relationship')),
  [ROUTES.PLACE_SETTINGS]: lazyRetry(() => import('../profile/birth-place')),
  [ROUTES.PRIVACY_POLICY]: lazyRetry(() => import('../settings/privacy-policy')),
  [ROUTES.TERMS]: lazyRetry(() => import('../settings/terms')),
  [ROUTES.TODAY_MATCHES]: lazyRetry(() => import('../matches')),
  [ROUTES.TODAY_FEATURE]: lazyRetry(() => import('../today-feature')),
  [ROUTES.PERSONAL_READINGS]: lazyRetry(() => import('../personal-readings')),
  [ROUTES.QUESTIONNAIRE]: lazyRetry(() => import('../guides/questionnaire')),
  [ROUTES.GUIDE_PAGE]: lazyRetry(() => import('../guides/guide-page')),
  [ROUTES.COMPATIBILITY_DASHBOARD]: lazyRetry(() => import('../compatibilities/compatibility-dashboard')),
  [ROUTES.BEST_MATCHES]: lazyRetry(() => import('../compatibilities/best-matches')),
  [ROUTES.SIGN_BEST_MATCHES]: lazyRetry(() => import('../compatibilities/best-matches/sign-best-matches')),
  [ROUTES.SUBSCRIPTION_WEB]: lazyRetry(() => import('../subscription/web')),
  [ROUTES.BIRTH_CHART_PLANET_INFO]: lazyRetry(() => import('../birth-chart/charts/components/planet-info')),
  [ROUTES.BIRTH_CHART_TRANSIT_DETAILS]: lazyRetry(() => import('../birth-chart/transits/components/description')),
  [ROUTES.GUIDES_MAGIC_SCREEN]: lazyRetry(() => import('../guides/magic-screen')),
  [ROUTES.GUIDES_SESSION_SCREEN]: lazyRetry(() => import('../guides/session')),
  [ROUTES.CONGRATULATIONS]: lazyRetry(() => import('../guides/congratulations')),
  [ROUTES.DATING_CALENDAR]: lazyRetry(() => import('../calendars/dating-calendar')),
  [ROUTES.LUNAR_CALENDAR]: lazyRetry(() => import('../calendars/lunar-calendar')),
  [ROUTES.HIDDEN_MENU]: lazyRetry(() => import('../hidden-menu')),
  [ROUTES.STORIES]: lazyRetry(() => import('../stories/content')),
  [ROUTES.ASTROLOGER_REPORT]: lazyRetry(() => import('../astrologers/astrologer-report')),
  [ROUTES.ASTROLOGER_EMAIL]: lazyRetry(() => import('../astrologers/astrologer-email')),
  [ROUTES.ASTROLOGER_WAIT]: lazyRetry(() => import('../astrologers/astrologer-wait')),
  [ROUTES.PALM_READING_DAILY]: lazyRetry(() => import('../palm-reading-daily')),
  [ROUTES.PALM_READING_DAILY_CAMERA]: lazyRetry(() => import('../palm-reading-daily/camera')),
  [ROUTES.DEV]: lazyRetry(() => import('../dev')),
  [ROUTES.SUPPORT]: lazyRetry(() => import('../support')),
  [ROUTES.TALLY_QUESTIONNAIRE]: lazyRetry(() => import('../support/tally-questionnaire')),
  [ROUTES.LANGUAGE]: lazyRetry(() => import('../language')),
  [ROUTES.AUTH_BY_EMAIL]: lazyRetry(() => import('../auth')),
  [ROUTES.AUTH_SUCCESS]: lazyRetry(() => import('../auth/success')),
  [ROUTES.ASTRO_CALENDAR]: lazyRetry(() => import('../astro-calendar')),
  [ROUTES.BIRTH_CHART_ABOUT_TRANSITS_ASPECTS]: lazyRetry(() => import('../birth-chart/about-transits-aspects')),
  [ROUTES.REPORTS_WEB_VIEW]: lazyRetry(() => import('../reports-web-view')),
  [ROUTES.BIRTH_CHART]: lazyRetry(() => import('../birth-chart')),
  [ROUTES.PWA_ONBOARDING]: lazyRetry(() => import('../web/pwa-instructions/pwa-onboarding')),

  [ROUTES.ADVISORS_CONNECTION]: lazyRetry(() => import('../advisors/screens/connection')),
  [ROUTES.ADVISORS_CHAT]: lazyRetry(() => import('../advisors/screens/chat')),
  [ROUTES.ADVISORS_CHAT_WITHOUT_ANIM]: lazyRetry(() => import('../advisors/screens/chat')),
  [ROUTES.ADVISORS_PALM_SCAN]: lazyRetry(() => import('../advisors/screens/chat/palm-scan')),
  [ROUTES.ADVISORS_CHATS]: lazyRetry(() => import('../advisors/screens/chats')),
  [ROUTES.ADVISORS_CATALOG]: lazyRetry(() => import('../advisors/screens/catalog')),
  [ROUTES.ADVISORS_ALL]: lazyRetry(() => import('../advisors/screens/all-advisors')),
  [ROUTES.ADVISORS_QUESTIONS]: lazyRetry(() => import('../advisors/screens/questions')),
  [ROUTES.ADVISORS_SERVICE]: lazyRetry(() => import('../advisors/screens/advisors-tabs/screens/service')),
  [ROUTES.ADVISOR_PAGE]: lazyRetry(() => import('../advisors/screens/advisor-page')),
  [ROUTES.ADVISORS_CHAT_READ]: lazyRetry(() => import('../advisors/screens/chat-read')),
  [ROUTES.ADVISORS_PACKS]: lazyRetry(() => import('../advisors/screens/monetization/packs')),
  [ROUTES.ADVISORS_PACK_SPECIAL_OFFER]: lazyRetry(() => import('../advisors/screens/monetization/offers/special')),
  [ROUTES.ADVISORS_PACK_WELCOME_OFFER]: lazyRetry(() => import('../advisors/screens/monetization/offers/welcome')),
  [ROUTES.ADVISORS_PACK_SPECIAL_OFFER_2]: lazyRetry(() => import('../advisors/screens/monetization/offers/special-2')),
  [ROUTES.SUBSCRIPTION_SUB_BENEFITS]: lazyRetry(() => import('../subscription/sub-benefits')),
  [ROUTES.SUBSCRIPTION_SUB_BENEFITS_WITHOUT_ANIM]: lazyRetry(() => import('../subscription/sub-benefits')),
  [ROUTES.SUBSCRIPTION_BENEFITS_OFFER]: lazyRetry(() => import('../subscription/benefits-offer')),
  [ROUTES.SUBSCRIPTION_BENEFITS_OFFER_WITHOUT_ANIM]: lazyRetry(() => import('../subscription/benefits-offer')),
};

export default SCREENS;
