import React, { FC, memo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { useSelector } from 'react-redux';
import { sw } from '@wowmaking/ui/src/utils';

import { RootState } from 'store';

import { EXTERNAL_TRIGGER_NOTIFICATIONS_MAP } from './components';

interface Props {
  style?: StyleProp<ViewStyle>;
}

const TriggerNotification: FC<Props> = ({ style }) => {
  const notifications = useSelector((state: RootState) => state.astrologers.notifications.triggerNotifications);

  if (!notifications?.length) {
    return null;
  }

  return (
    <View style={[styles.container, style]}>
      {notifications.map(notification => {
        const Component = EXTERNAL_TRIGGER_NOTIFICATIONS_MAP?.[notification.type];

        if (!Component) {
          return null;
        }

        return <Component key={notification.type} />;
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    minHeight: sw(50),
    flexDirection: 'row',
    width: '100%',
    overflow: 'hidden',
  },
});

export default memo(TriggerNotification);
