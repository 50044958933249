import { createSelector } from 'reselect';
import { uniqBy } from 'lodash';

import { Astrologist } from 'api/astrology-chat/constants';
import { RootState } from 'store';
import { ActivityType } from 'screens/advisors/screens/chats/components/typing-component';

import { getActiveAdvisors } from '../selectors';

const getAstrologersChats = (state: RootState) => state.astrologers.chat.chats;
const getTypingAdvisors = (state: RootState) => state.astrologers.chat.typingIds;
const getRecordingAdvisors = (state: RootState) => state.astrologers.chat.recordingIds;
const getAdvisorsPid = (state: RootState) => state.remoteConfig.advisorsPid;
const getAdvisorsCatalogStealthCategoryPid = (state: RootState) => state.remoteConfig.advisorsCatalogStealthCategoryPid;
const getStealthModeStatus = (state: RootState) => state.remoteConfig.stealthModeEnabled;

export const getChatsDataWithReviews = createSelector([getAstrologersChats], chats => {
  const chatsValues = Object.values(chats);
  return chatsValues.filter(chat => chat?.isReviewCompleted && chat?.rate);
});

export const getAdvisorsPositiveReview = createSelector([getChatsDataWithReviews], reviews => {
  const positiveReview = reviews.find(review => !!review?.rate && review?.rate >= 4);
  return positiveReview?.rate;
});

export const selectChatMessagesById = createSelector([getAstrologersChats, (_state, id: Astrologist['astrologer_id']) => id], (chats, id) => {
  return uniqBy(chats?.[id]?.messages, 'created_at') || [];
});

export const selectAdvisorActivity = createSelector(
  [getTypingAdvisors, getRecordingAdvisors, (_state, id: Astrologist['astrologer_id']) => id],
  (typingAdvisors, recordingAdvisors, id): ActivityType | null => {
    let activityType: ActivityType | null = null;

    if (recordingAdvisors.includes(id)) {
      activityType = 'recording';
    }

    if (typingAdvisors.includes(id)) {
      activityType = 'typing';
    }

    return activityType;
  },
);

export const selectValidAdvisorsPid = createSelector(
  [getActiveAdvisors, getAdvisorsPid, getAdvisorsCatalogStealthCategoryPid, getStealthModeStatus, (_state, id: Astrologist['astrologer_id']) => id],
  (advisors, advisorsPid, stealthCategories, stealthMode, id): number | string => {
    if (stealthMode) {
      const advisorData = advisors.find(a => a.astrologer_id === id);

      if (advisorData && advisorData?.stealth_category) {
        return stealthCategories[advisorData.stealth_category] || advisorsPid;
      }
    }

    return advisorsPid;
  },
);
