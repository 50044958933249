import { Platform } from 'react-native';
import DeviceProps from '@magnus/react-native-device-props';

import type { HelpDeskConfig } from 'screens/support/types';

export const isStage = (): boolean => {
  return DeviceProps.getAppVersion().indexOf('-') !== -1;
};

export const getMerchantId = (): string => {
  return Platform.select({
    ios: 'merchant.com.atrixapp',
    android: isStage() ? 'api_pk_c48c0b09_4ecb_4083_b0fb_ed8f250ef08a' : 'api_pk_5f595a17_1a04_415c_ab4c_57fc2aec46f6',
    default: '',
  });
};

export const getWebSubCancelLink = (): string => {
  const webLinkDevDefiner = isStage() ? '-stage' : '';
  const webAppUrl = Platform.OS === 'web' ? process.env.REACT_APP_WEB_SUB_URL : `https://advisors${webLinkDevDefiner}.astroline.today`;
  return `${webAppUrl}/terminate?click_source=app&email=`;
};

export const getAsbUrl = (): string => {
  if (Platform.OS === 'web') {
    return process.env.REACT_APP_WEB_ASB_URL || '';
  }
  return isStage() ? 'https://ask-stage.atrix-app.com/asb' : 'https://ask.atrix-app.com/asb';
};

export const PROJECT_ID = Platform.select({
  ios: 'atrix-ios',
  android: 'atrix-android',
  web: process.env.REACT_APP_PROJECT_ID,
  default: 'undetected',
});

export const APP_NAME = Platform.select({
  ios: 'Atrix',
  android: 'Atrix',
  web: process.env.REACT_APP_TITLE,
});

export const BUNDLE_ID = 'com.atrixapp';

export const IOS_APP_ID = '6737768525';

export const MAGNUS_TOKEN = Platform.select({
  android: 'RYRm15zig6NE5JgiNV7OmgmJBKXBAvPO',
  ios: 'IxhWrG0qRkhL6TYbxQo9592oDcX8G1zs',
  web: process.env.REACT_APP_MAGNUS_TOKEN,
  default: '',
});

export const FACEBOOK_APP_ID = '901682178581919';

export const GOOGLE_CLOUD_API_KEY = Platform.select({
  android: 'AIzaSyAFkG9VjObcDheHl_7ToMBz-0A3ENo42UE',
  ios: 'AIzaSyBUsLvdrbGeXYZRysM_AAlLK-c0R4-oVts',
});

export const TITLE = Platform.select({
  android: 'Atrix',
  ios: 'Atrix',
  web: process.env.REACT_APP_TITLE,
});

export const SUB_CANCEL_LINK = Platform.select({
  ios: 'itms-apps://apps.apple.com/account/subscriptions',
  android: 'https://play.google.com/store/account/subscriptions',
});

export const SUPPORT_TOPIC = Platform.select({
  android: '[Atrix]',
  ios: '[Atrix]',
  web: `[${process.env.REACT_APP_TITLE}]`,
});

export const ZENDESK_TOKEN =
  Platform.OS === 'web' ? process.env.REACT_APP_ZENDESK_TOKEN : '74f1278a060753c72a41192ad686820c7199cea526dd8881965d72eb02a274bb';

export const HELPDESK_CONFIG: HelpDeskConfig = {
  agentId: '',
  teamIdMobile: 'a8cf6111-3245-4b65-a65d-9b6ee2cdfde1',
  teamIdWeb: 'df0715f5-1dfa-4575-a6a4-bfa9f084eb25',
  appTagIos: 'b0476359-9220-4e3e-a39f-7eb8a56e9943',
  appTagAndroid: 'db85c4e5-76d4-4871-b03b-517f24c07cf3',
  purchaseTagMobile: '97fddd83-a1a5-447d-a458-084e644e95e8',
  purchaseTagWeb: 'f4312e37-5309-4829-a7b5-dfd48a26c372',
};

export const SUPPORT_EMAIL = 'atrix@support-team.app';
export const SUPPORT_EMAIL_WEB = 'atrix.app@support-team.app';

export const STORE_LINK = Platform.select({
  ios: `itms-apps://apps.apple.com/app/id${IOS_APP_ID}?action=write-review`,
  android: `https://play.google.com/store/apps/details?id=${BUNDLE_ID}`,
});

export const ADJUST_APP_TOKEN = 'ik5c1e7wza4g';

export const ADJUST_UNIVERSAL_LINK = 'f6sw.adj.st';

export const ADJUST_EVENTS = {
  view_product: 'b36u4o',
  subscription_success: '4bhk2p',
  app_activity: 'lqjoci',
};

export const DO_NOT_REMEMBER = 2400;

export const ADJUST_OFFER_QUERY_PARAM = 'offer';

export const TRUST_REDIRECT_URI = '/review-success.html';

export const TRUST_PILOT_URL = 'https://www.trustpilot.com/evaluate/astroline.today';
