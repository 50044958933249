import dayjs from 'dayjs';

import { ADVISORS_STATUSES } from 'components/advisors/active-status';

export const checkAdvisorWorkingStatus = (workTime?: string): ADVISORS_STATUSES => {
  if (!workTime) {
    return ADVISORS_STATUSES.ONLINE;
  }

  const [startWork, endWork] = workTime.split('-');
  const format = 'HH:mm';

  const startTime = dayjs(startWork, format);
  let endTime = dayjs(endWork, format);

  if (!startTime.isBefore(endTime)) {
    endTime = endTime.add(1, 'day');
  }

  return dayjs().isBetween(startTime, endTime, 'minute', '[)') ? ADVISORS_STATUSES.ONLINE : ADVISORS_STATUSES.OFFLINE;
};
