import {
  ADVISORS_CATALOG_CUSTOM_BLOCK_IDS,
  ADVISORS_STEALTH_CATEGORIES,
  ADVISORS_CATEGORIES,
  AdvisorsCatalogBlocks,
  AdvisorsCatalogStealthCategoryPid,
  ADVISORS_CUSTOM_STEALTH_CATEGORIES,
} from '../types';
import AllAdvisorsBlock from '../components/all-advisors-block';
import AdvisorsCategory from '../components/advisors-category';
import RandomAdvisorBlock from '../components/random-advisor';
import MyChatsBlock from '../components/my-chats-block';

export const CATALOG_BLOCKS: AdvisorsCatalogBlocks = {
  [ADVISORS_CATALOG_CUSTOM_BLOCK_IDS.RANDOM]: RandomAdvisorBlock,
  [ADVISORS_CATALOG_CUSTOM_BLOCK_IDS.CHATS]: MyChatsBlock,
  [ADVISORS_CATEGORIES.PREMIUM]: AdvisorsCategory,
  [ADVISORS_CATEGORIES.RECOMMENDED]: AdvisorsCategory,
  [ADVISORS_CATEGORIES.LOVE]: AdvisorsCategory,
  [ADVISORS_CATEGORIES.ACCURATE]: AdvisorsCategory,
  [ADVISORS_CATALOG_CUSTOM_BLOCK_IDS.ALL]: AllAdvisorsBlock,
  [ADVISORS_STEALTH_CATEGORIES.COOKING]: AdvisorsCategory,
  [ADVISORS_STEALTH_CATEGORIES.MATH]: AdvisorsCategory,
  [ADVISORS_STEALTH_CATEGORIES.HISTORY]: AdvisorsCategory,
  [ADVISORS_STEALTH_CATEGORIES.HR_ADVISORS]: AdvisorsCategory,
  [ADVISORS_CUSTOM_STEALTH_CATEGORIES.ASTROLOGY]: AdvisorsCategory,
};

export const defaultAdvisorsCatalogStealthCategoryPid: AdvisorsCatalogStealthCategoryPid = {
  [ADVISORS_STEALTH_CATEGORIES.COOKING]: 87,
  [ADVISORS_CUSTOM_STEALTH_CATEGORIES.ASTROLOGY]: '',
  [ADVISORS_CATEGORIES.LOVE]: '',
  [ADVISORS_STEALTH_CATEGORIES.MATH]: 88,
  [ADVISORS_STEALTH_CATEGORIES.HISTORY]: 92,
  [ADVISORS_STEALTH_CATEGORIES.HR_ADVISORS]: 93,
};
