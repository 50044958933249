import isEmpty from 'lodash/isEmpty';

const queryParams = window.location.search;
const originUrl = `${window.location.origin}`;
const startUrl = `${originUrl}/${isEmpty(queryParams) ? '' : queryParams}`;
const appName = process.env.REACT_APP_TITLE;

const userAgent = window.navigator.userAgent.toLowerCase();
const platform = /iphone|ipad|ipod/.test(userAgent) ? 'ios' : 'android';

const manifest = {
  name: `${appName}`,
  short_name: `${appName}`,
  start_url: startUrl,
  display: 'standalone',
  orientation: 'portrait',
  theme_color: '#000000',
  background_color: '#000000',
  icons: [
    {
      src: `${originUrl}/icons-192-${platform}.png`,
      type: 'image/png',
      sizes: '192x192',
      purpose: 'any',
    },
    {
      src: `${originUrl}/icons-192-${platform}.png`,
      type: 'image/png',
      sizes: '192x192',
      purpose: 'maskable',
    },
    {
      src: `${originUrl}/icons-512-${platform}.png`,
      type: 'image/png',
      sizes: '512x512',
    },
  ],
};

const content = encodeURIComponent(JSON.stringify(manifest));
const manifestUrl = 'data:application/manifest+json,' + content;

const element = document.createElement('link');

element.setAttribute('rel', 'manifest');
element.setAttribute('href', manifestUrl);
document.querySelector('head').appendChild(element);
