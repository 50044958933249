import React from 'react';
import { StyleProp, ViewStyle, StyleSheet, View, Image, TextStyle, ImageStyle } from 'react-native';
import { fs } from '@wowmaking/ui/src/utils';

import Text from 'components/text';
import * as COLORS from 'constants/colors';
import { FONT_TYPES } from 'constants/fonts';

import { ICONS_MAP } from '../../constants';

interface Props {
  children?: string;
  style?: StyleProp<ViewStyle>;
  iconWrapStyle?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ImageStyle>;
  textStyle?: StyleProp<TextStyle>;
  highlightTextStyle?: StyleProp<TextStyle>;
  font?: FONT_TYPES;
}

const HighlightText: React.FC<Props> = ({
  children = null,
  style = null,
  iconWrapStyle = null,
  iconStyle = null,
  textStyle = null,
  highlightTextStyle = null,
  font = 'OpenSans',
}) => {
  if (typeof children !== 'string') {
    return null;
  }

  const renderText = () => {
    const parts = children.split(/\*\*(.*?)\*\*|##(.*?)##/);

    return (
      <Text font={font} style={[styles.text, textStyle]}>
        {parts.map((part, index) => {
          if (index % 3 === 0) {
            return (
              <Text font={font} key={index}>
                {part}
              </Text>
            );
          } else if (index % 3 === 1) {
            return (
              <Text font={font} key={index} style={[styles.bold, highlightTextStyle]}>
                {part}
              </Text>
            );
          } else {
            const iconName = part && part.indexOf('_ICON') >= 0 ? part : null;

            if (iconName) {
              const iconSource = ICONS_MAP[iconName];
              return (
                <View key={index} style={[styles.iconWrap, iconWrapStyle]}>
                  <Image source={iconSource} style={[styles.icon, iconStyle]} />
                </View>
              );
            } else {
              return null;
            }
          }
        })}
      </Text>
    );
  };

  return <View style={[styles.wrap, style]}>{renderText()}</View>;
};

export default HighlightText;

const styles = StyleSheet.create({
  wrap: {},
  text: {
    fontSize: fs(17),
    lineHeight: 24,
    color: COLORS.WHITE_WITH_FIFTH_TRANSPARENCY,
  },
  bold: {
    fontSize: fs(17),
    lineHeight: 24,
    fontWeight: '600',
    color: COLORS.WHITE,
  },
  iconWrap: {
    width: 24,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    position: 'absolute',
    top: -19,
    left: 0,
    right: 0,
  },
});
