import { createAction } from 'redux-actions';
import { Platform } from 'react-native';
import DeviceProps from '@magnus/react-native-device-props';

import { AppDispatch, AppGetState, AppThunk } from 'store';
import { addNotificationToQueue } from 'store/notification-center/actions';
import { closeCurrentModal, showModal } from 'store/modals/actions';
import Analytics from 'analytics';
import { isStandalone, isAndroid } from 'utils/pwa';
import { BROWSERS_NAMES } from 'constants/pwa';
import * as MODALS from 'constants/modals';
import { NOTIFICATIONS } from 'constants/notification-center';
import { MODALS_EXCLUDE_ROUTES, MODALS_EXCLUDE_ROUTES_IN_CHAT } from 'constants/routes';
import { getPromocode } from 'api/astrology-chat';
import { ASTRO_CALENDAR_PLACE } from 'screens/astro-calendar/constants';
import { checkIsPwaInstalled } from 'store/astro-calendar/actions';

import {
  SET_INSTALLED,
  SET_BANNER_SESSION_STARTED,
  SET_BROWSER_NAME,
  SET_PWA_AUTO_INSTALL_AVAILABLE,
  SET_INSTALL_MOBILE_APP_SHOW_COUNT,
  SET_INSTALL_MOBILE_APP_PROMOCODE_SECONDS,
  SET_AFTER_CHAT_MODAL_SHOW,
  SET_MODAL_SHOWN,
} from './types';

const setInstalled = createAction(SET_INSTALLED);
const setBannerSessionStartedAction = createAction(SET_BANNER_SESSION_STARTED);
const setBrowserName = createAction(SET_BROWSER_NAME);
const setPWAAutoInstallAvailable = createAction(SET_PWA_AUTO_INSTALL_AVAILABLE);
const setInstallMobileAppShowCount = createAction(SET_INSTALL_MOBILE_APP_SHOW_COUNT);
const setInstallMobileAppPromocodeSeconds = createAction(SET_INSTALL_MOBILE_APP_PROMOCODE_SECONDS);
export const setModalShown = createAction(SET_MODAL_SHOWN);
export const setAfterChatModalShow = createAction(SET_AFTER_CHAT_MODAL_SHOW);

let installPrompt: any;

export const setBannerSessionStarted = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      pwa: { bannerSessionStarted },
    } = getState();

    const sessionNumber = (Analytics.getSessionNumber() ?? 0) + 1;

    if (!bannerSessionStarted) {
      dispatch(setBannerSessionStartedAction(sessionNumber));
    }
  };
};

export const initPWAListeners = (): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    if (Platform.OS !== 'web') {
      return null;
    }

    const {
      pwa: { installed },
    } = getState();

    const deviceInfo: any = await DeviceProps.fetch();

    if (deviceInfo) {
      dispatch(setBrowserName(deviceInfo.browser_name as unknown as BROWSERS_NAMES));
    }

    const isStandaloneMode = isStandalone();

    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;

    if (currentSession === 1 && !isStandaloneMode) {
      Analytics.setUserProperty('isPWA', false);
    }

    if (!installed && isStandaloneMode) {
      Analytics.setUserProperty('isPWA', true);
      dispatch(setInstalled(true));
    }

    if (isAndroid()) {
      // WORKS ON ANDROID(CHROME) ONLY
      // https://caniuse.com/?search=beforeinstallprompt
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault();
        if (!installPrompt) {
          Analytics.trackEvent('PWA_Install_Prompt', 'Available');
        }

        installPrompt = e;
        dispatch(setPWAAutoInstallAvailable(true));
      });

      // TO CHECK PWA WAS INSTALLED
      // https://caniuse.com/?search=appinstalled
      // window.addEventListener('appinstalled', e => {
      //   e.preventDefault();

      //   alert('APP INSTALLED!');
      // });
    }
  };
};

export const installPWA = (): AppThunk => {
  return async () => {
    if (!installPrompt) {
      return;
    }

    Analytics.trackEvent('PWA_Install_Prompt', 'Show');

    installPrompt.prompt();

    const { outcome } = await installPrompt.userChoice;

    if (outcome === 'accepted') {
      Analytics.trackEvent('PWA_Install_Prompt', 'Accepted');
    } else if (outcome === 'dismissed') {
      Analytics.trackEvent('PWA_Install_Prompt', 'Declined');
    }

    installPrompt = null;
  };
};

export const initPWAInstructions = (): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    if (Platform.OS !== 'web') {
      return null;
    }

    const {
      profile: { isUserLoyal },
      pwa: { bannerSessionStarted },
      remoteConfig: {
        notificationCenter: {
          pwaInstructions: {
            enabled: pwaNotificationEnabled,
            sessionStartNumber: pwaNotificationMinSession,
            sessionNumber: pwaNotificationMaxSession,
          },
        },
        pwaInstructions: { sessionStartNumber: pwaStartSession, timeout: pwaTimeout, showSessionsLimit: pwaSessionsLimit, showInChat: pwaShowInChat },
      },
    } = getState();

    const pwaInstructionsEndSession = pwaStartSession + pwaSessionsLimit;

    const isStandaloneMode = isStandalone();

    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;

    const pwaNotificationSessionsShowed = currentSession - bannerSessionStarted;

    if (!isStandaloneMode) {
      if (currentSession >= pwaStartSession && currentSession < pwaInstructionsEndSession) {
        setTimeout(() => {
          const state = getState();
          const {
            navigation: { currentRoute },
            modals: { activeModal },
            notificationCenter: { wasPressed: pressedNotifications },
          } = state;

          const wasPWANotificationPressed = pressedNotifications.find((n: NOTIFICATIONS) => n === NOTIFICATIONS.PWA_INSTRUCTIONS);

          const excludeRoutes = pwaShowInChat ? MODALS_EXCLUDE_ROUTES : MODALS_EXCLUDE_ROUTES_IN_CHAT;

          if (!wasPWANotificationPressed && !activeModal) {
            if (excludeRoutes.includes(currentRoute)) {
              dispatch(setAfterChatModalShow(true));
            } else {
              dispatch(showModal(MODALS.PWA_INSTRUCTION));
              dispatch(setModalShown());
            }
          }
        }, pwaTimeout * 1000);
      }

      if (
        pwaNotificationEnabled &&
        currentSession >= pwaNotificationMinSession &&
        pwaNotificationSessionsShowed <= pwaNotificationMaxSession &&
        !isUserLoyal
      ) {
        dispatch(addNotificationToQueue(NOTIFICATIONS.PWA_INSTRUCTIONS));
      }
    }
  };
};

export const initInstallMobileAppInstructions = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: { installMobileAppModalConfig },
      pwa: { installMobileAppShowCount },
      profile: {
        profileData: { userParams },
      },
    } = getState();

    const isEnabled = !!installMobileAppModalConfig?.enabled;
    const promocode = installMobileAppModalConfig?.promocode;
    const showCount = installMobileAppModalConfig?.showSessionLimits || 10;
    const isAppInstalled = userParams?.app_installed;

    if (Platform.OS !== 'web' || isStandalone() || !isEnabled || installMobileAppShowCount >= showCount || isAppInstalled) {
      return false;
    }

    const onShow = (seconds = 0) => {
      dispatch(setInstallMobileAppPromocodeSeconds(seconds));
      dispatch(showModal(MODALS.INSTALL_MOBILE_APP));
      dispatch(setInstallMobileAppShowCount(installMobileAppShowCount + 1));
    };

    try {
      const promocodeInfo = await getPromocode(promocode);

      onShow(promocodeInfo?.seconds);
    } catch (error) {
      console.warn('ERROR getPromocode', error);
      onShow();
    }
  };
};

export const initInstructionsModals = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      profile: { isUserLoyal },
      pwa: { modalShown },
    } = getState();

    if (modalShown) {
      return;
    }

    if (isUserLoyal) {
      return dispatch(initInstallMobileAppInstructions());
    }

    return dispatch(initPWAInstructions());
  };
};

export const closePwaModal = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      modals: { params },
    } = getState();
    const isAstroCalendarOrigin = params?.place === ASTRO_CALENDAR_PLACE;

    Analytics.trackEvent('PWA_Instructions', 'Close');

    dispatch(closeCurrentModal());
    if (isAstroCalendarOrigin) {
      dispatch(checkIsPwaInstalled());
    }
  };
};
