import React, { FC, memo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { color } from '@wowmaking/ui/src/utils';

import * as COLORS from 'constants/colors';
import Text from 'components/text';
import { t2 } from 'localization';

export enum ADVISORS_STATUSES {
  ONLINE = 'online',
  BUSY = 'busy',
  OFFLINE = 'offline',
}

interface Props {
  status: ADVISORS_STATUSES;
  liteMode?: boolean;
  textHide?: boolean;
  bulletHide?: boolean;
  style?: StyleProp<ViewStyle>;
}

const ActiveStatus: FC<Props> = ({ status, liteMode = false, style = null, textHide = false, bulletHide = false }) => {
  const isOnline = status === ADVISORS_STATUSES.ONLINE || status === ADVISORS_STATUSES.BUSY;
  const currentStatusColor =
    status === ADVISORS_STATUSES.ONLINE ? COLORS.AQUA : status === ADVISORS_STATUSES.BUSY ? COLORS.ORANGE : color(COLORS.BEIGE, 0.5);

  return liteMode ? (
    <View style={[styles.iconDot, style]}>
      <View style={[styles.iconStatusDots, { backgroundColor: currentStatusColor }]}>{!isOnline && <View style={styles.iconDotOffline} />}</View>
    </View>
  ) : (
    <View style={styles.wr}>
      {!bulletHide && <View style={[styles.iconDotDescription, { backgroundColor: currentStatusColor }]} />}
      {!textHide && <Text style={[styles.text, { color: currentStatusColor }]}>{t2(`ASTROLOGERS.STATUS.${status.toUpperCase()}`)}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  wr: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconDotDescription: {
    width: 6,
    height: 6,
    borderRadius: 100,
    marginRight: 4,
  },
  text: {
    fontSize: 10,
    fontWeight: '400',
  },
  iconDot: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 14,
    height: 14,
    borderRadius: 100,
    bottom: 0,
    padding: 3,
    backgroundColor: COLORS.DARK_1,
  },
  iconStatusDots: {
    width: 8,
    height: 8,
    borderRadius: 100,
    padding: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconDotOffline: {
    width: 6,
    height: 6,
    borderRadius: 100,
    backgroundColor: COLORS.DARK_1,
  },
});

export default memo(ActiveStatus);
