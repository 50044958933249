import React, { FC, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet } from 'react-native';
import { fs } from '@wowmaking/ui/src/utils';

import { AppDispatch, RootState } from 'store';
import { handleInstallMobileNotification, removeTriggerNotifications } from 'store/astrologers/notifications/actions';
import { t } from 'localization';
import { formatMinutes } from 'utils/astrologist';
import { isStandalone } from 'utils/pwa';
import HighLightText from 'components/hight-light-text';
import { selectTriggerNotificationSeconds } from 'store/astrologers/notifications/selectors';
import { DARK_TEAL } from 'constants/colors';
import { getAppIcon } from 'utils/app-icon-platform';

import BaseTriggerNotification from './base';

import { EXTERNAL_TRIGGER_NOTIFICATIONS } from './index';

interface Props {}

const InstallMobile: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const installMobileConfig = useSelector((state: RootState) => state.remoteConfig.installMobileNotification);
  const seconds = useSelector((state: RootState) => selectTriggerNotificationSeconds(state, EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP));
  const isCloseIconVisible = !!installMobileConfig?.closeEnabled;

  const text = useMemo(
    () => (seconds ? t('TRIGGER_NOTIFICATION.INSTALL_APP', { minutes: formatMinutes(seconds) }) : t('TRIGGER_NOTIFICATION.INSTALL_APP_WITHOUT_TIME')),
    [seconds],
  );

  const handlePress = () => {
    dispatch(handleInstallMobileNotification());
  };

  const handleClose = () => {
    dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP]));
  };

  if (isStandalone()) {
    return null;
  }

  return (
    <BaseTriggerNotification
      icon={getAppIcon}
      onClose={isCloseIconVisible ? handleClose : undefined}
      onPress={handlePress}
      type={EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP}>
      <HighLightText style={styles.text}>{text}</HighLightText>
    </BaseTriggerNotification>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: fs(12),
    fontWeight: '600',
    color: DARK_TEAL,
    lineHeight: fs(20),
    flexWrap: 'wrap',
    flexShrink: 1,
    height: '100%',
  },
});

export default memo(InstallMobile);
