import { createAction } from 'redux-actions';
import _ from 'lodash';
import dayjs from 'dayjs';

import { AppDispatch, AppGetState, AppThunk } from 'store';
import { ADVISORS_STEALTH_CATEGORIES, ADVISORS_CATEGORIES, ADVISORS_CUSTOM_STEALTH_CATEGORIES } from 'screens/advisors/screens/catalog/types';
import * as ROUTES from 'constants/routes';
import { ASTROLOGERS_OPEN_PLACE, TOP_TAB_IDS } from 'screens/advisors/constants';
import * as TRIGGERS from 'constants/monetization-triggers';
import { navigateWithMonetization } from 'store/unlock-content/actions';
import { navigate } from 'store/navigation/actions';
import { isValidCatalogBlock } from 'screens/advisors/screens/catalog/utils';

import { requestAstrologistReviews, setAstrologistAction } from '../core/actions';
import { checkAdvisorIsOnline, getActiveAdvisors, getAstrologistDataById, selectIsPremiumWithoutForcedOnlineAdvisor } from '../selectors';

import { TYPES } from './types';

const RANDOM_NUMBER_OF_ADVISORS = 7;

export const setCategories = createAction(TYPES.SET_CATEGORIES);
export const setPreviousDateForThreeDays = createAction(TYPES.SET_PREVIOUS_DATE_FOR_THREE_DAYS);
export const setPreviousDateForSevenDays = createAction(TYPES.SET_PREVIOUS_DATE_FOR_SEVEN_DAYS);

export const initAdvisorsCatalog = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const {
      astrologers: {
        catalog: { previousDateForThreeDays, previousDateForSevenDays },
      },
      remoteConfig: { stealthModeEnabled },
    } = state;

    if (stealthModeEnabled) {
      dispatch(initStealthAdvisorsCategories());
    }

    const allActiveAdvisors = getActiveAdvisors(state);
    const availableAdvisors = stealthModeEnabled ? allActiveAdvisors.filter(advisor => !advisor.stealth_category) : allActiveAdvisors;
    const ids = availableAdvisors.map(item => item.astrologer_id);
    const getRandomAdvisors = () => _.sampleSize(ids, RANDOM_NUMBER_OF_ADVISORS);

    const generateItemsForThreeDays = !previousDateForThreeDays || dayjs(previousDateForThreeDays).add(3, 'day').isSame(dayjs(), 'days');
    const generateItemsForSevenDays = !previousDateForSevenDays || dayjs(previousDateForSevenDays).add(7, 'day').isSame(dayjs(), 'days');

    if (generateItemsForThreeDays) {
      dispatch(setCategories({ [ADVISORS_CATEGORIES.RECOMMENDED]: getRandomAdvisors(), [ADVISORS_CATEGORIES.LOVE]: getRandomAdvisors() }));
      if (stealthModeEnabled) {
        dispatch(setCategories({ [ADVISORS_CUSTOM_STEALTH_CATEGORIES.ASTROLOGY]: getRandomAdvisors() }));
      }
      dispatch(setPreviousDateForThreeDays(dayjs().format()));
    }

    if (generateItemsForSevenDays) {
      dispatch(setCategories({ [ADVISORS_CATEGORIES.ACCURATE]: getRandomAdvisors() }));
      dispatch(setPreviousDateForSevenDays(dayjs().format()));
    }

    const premiumIds = availableAdvisors.flatMap(item => (item.is_premium ? item.astrologer_id : []));
    dispatch(setCategories({ [ADVISORS_CATEGORIES.PREMIUM]: premiumIds }));
  };
};

export const initStealthAdvisorsCategories = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      remoteConfig: { advisorsCatalogStealthCategoryPid },
    } = state;

    const allActiveAdvisors = getActiveAdvisors(state);
    const availableCategories = (Object.keys(advisorsCatalogStealthCategoryPid) as ADVISORS_STEALTH_CATEGORIES[]).filter(
      category => isValidCatalogBlock(category) && Object.values(ADVISORS_STEALTH_CATEGORIES).includes(category),
    );
    const stealthCategories = availableCategories.reduce((acc, category) => {
      const availableAdvisors = allActiveAdvisors.filter(advisor => advisor.stealth_category === category);
      return {
        ...acc,
        [category]: availableAdvisors.map(advisor => advisor.astrologer_id),
      };
    }, {});

    dispatch(setCategories(stealthCategories));
  };
};

export const openChatWithRandomAstrologist = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const advisors = getActiveAdvisors(state);
    const filterAdvisors = advisors.filter(advisor => checkAdvisorIsOnline(state, advisor.astrologer_id));

    const astrologist = state.astrologers.core.astrologist;

    const randomAstrologist = _.sample(_.without(filterAdvisors, astrologist));

    dispatch(selectAnAdvisor(randomAstrologist?.astrologer_id || astrologist.astrologer_id));
  };
};

export const selectAnAdvisor = (id: number | string, destinationRoute?: TOP_TAB_IDS, place?: ASTROLOGERS_OPEN_PLACE): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const astrologist = getAstrologistDataById(state, id);

    if (astrologist) {
      dispatch(setAstrologistAction(astrologist));
      dispatch(requestAstrologistReviews(astrologist.astrologer_id));

      const originPlace = place || ASTROLOGERS_OPEN_PLACE.ADVISORS_CATALOG;
      const route = ROUTES.ADVISOR_PAGE;
      const params = { destinationRoute, place: originPlace };

      const isPremium = selectIsPremiumWithoutForcedOnlineAdvisor(state, astrologist);

      if (isPremium) {
        dispatch(
          navigateWithMonetization({
            trigger: TRIGGERS.PREMIUM_ADVISOR_CLICK,
            route,
            params,
          }),
        );

        return;
      }

      navigate(route, params);
    }
  };
};
