import React, { FC, memo } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle, Image, ImageStyle } from 'react-native';
import { fs, paddingHorizontal, sw } from '@wowmaking/ui/src/utils';

import { useAppSelector } from 'store';
import Text from 'components/text';
import * as COLORS from 'constants/colors';
import { getSoftCurrencyIcon } from 'components/advisors/soft-currency/utils';
import { convertMinutesToSoftCurrency } from 'screens/advisors/screens/monetization/utils';
import { FONT_TYPES } from 'constants/fonts';
import { getLangReadDirection } from 'localization/utils';

interface Props {
  minutesAmount?: number;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  iconStyle?: StyleProp<ImageStyle>;
  font?: FONT_TYPES;
  content?: React.ReactNode;
}

const SoftCurrencyAmount: FC<Props> = ({
  minutesAmount = 1,
  iconStyle = null,
  style = null,
  textStyle = null,
  font = 'OpenSans',
  content = null,
}) => {
  const softCurrencyConfig = useAppSelector(state => state.remoteConfig.advisorSoftCurrencyConfig);
  const { type, rate } = softCurrencyConfig;
  const currencyIcon = getSoftCurrencyIcon(type);
  const amount = convertMinutesToSoftCurrency(minutesAmount, rate);
  const langDirection = getLangReadDirection();

  const renderAmount = () => {
    return (
      <View style={styles.wrapper}>
        <Text font={font} style={[styles.currencyText, textStyle]}>
          {amount}
        </Text>
        <Image style={[styles.currencyIcon, iconStyle]} source={currencyIcon} />
      </View>
    );
  };

  return (
    <View style={[styles.root, style]}>
      {langDirection === 'ltr' && renderAmount()}
      {content}
      {langDirection === 'rtl' && renderAmount()}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  currencyIcon: {
    width: sw(20),
    height: sw(20),
    marginRight: paddingHorizontal(5),
    marginLeft: paddingHorizontal(5),
  },
  currencyText: {
    fontSize: fs(20),
    fontWeight: '700',
    color: COLORS.BEIGE_2,
    alignItems: 'center',
  },
});

export default memo(SoftCurrencyAmount);
