import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, View, Image, ScrollView } from 'react-native';
import { sw, BOTTOM_OFFSET, paddingVertical, fs, paddingHorizontal, color } from '@wowmaking/ui/src/utils';
import queryString from 'query-string';
import Modal from 'react-native-modal';
import Clipboard from '@react-native-clipboard/clipboard';

import Analytics from 'analytics';
import { useAppDispatch, useAppSelector } from 'store';
import Localization, { t } from 'localization';
import { getPromocode } from 'api/astrology-chat';
import useModalIsVisibly from 'hooks/use-modal-is-visibly';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import Text from 'components/text';
import CloseButtonWithTimeout from 'components/close-button-with-timeout';
import { isAllowedBrowserByType, isSafariBrowser } from 'utils/pwa';
import { formatMinutes } from 'utils/astrologist';
import { ASTRO_CALENDAR_PLACE } from 'screens/astro-calendar/constants';
import { closePwaModal, setAfterChatModalShow, setModalShown } from 'store/pwa/actions';
import { selectUserProfileIsLoaded } from 'store/profile/selectors';
import { convertMinutesToSoftCurrency } from 'screens/advisors/screens/monetization/utils';
import Button from 'components/button';
import { navigate } from 'store/navigation/actions';
import * as ROUTES from 'constants/routes';
import { closeCurrentModal, showModal } from 'store/modals/actions';
import { MODALS_EXCLUDE_ROUTES_IN_CHAT } from 'constants/routes';

import HighlightText from './components/highlight-text';
import ClipBoardTooltip from './components/clipboard-tooltip';
import ClipboardButton from './components/clipboard-button';
import CLOSE_ICON from './img/ic-cancel.png';
import SaveHome from './components/save-home';
import { getBrowserType, getInstructionGif, getInstructionImage } from './utils';
import { IS_SMALL_SCREEN } from './constants';

interface Params {
  place?: typeof ASTRO_CALENDAR_PLACE;
  animationDisabled?: boolean;
}

const HIDE_COPIED_TIME = 3000;

const PWAInstructions: FC = () => {
  const dispatch = useAppDispatch();

  const isVisible = useModalIsVisibly(MODALS.PWA_INSTRUCTION);
  const browserName = useAppSelector(state => state.pwa.browserName);
  const idfm = useAppSelector(state => state.auth.webUUID);
  const closeTimeout = useAppSelector(state => state.remoteConfig.pwaInstructions.closeBtnTimeout);
  const promocode = useAppSelector(state => state.remoteConfig.pwaInstructions.promocode);
  const softCurrency = useAppSelector(state => state.remoteConfig.advisorSoftCurrencyConfig);
  const isPWAAutoInstallAvailable = useAppSelector(state => state.pwa.isPWAAutoInstallAvailable);
  const modalParams = useAppSelector(state => state.modals.params) as Params;
  const userProfileIsLoaded = useAppSelector(selectUserProfileIsLoaded);
  const showAboutBtn = useAppSelector(state => state.remoteConfig.pwaInstructions.showAboutBtn);
  const currentRoute = useAppSelector(state => state.navigation.currentRoute);
  const afterChatModalShow = useAppSelector(state => state.pwa.afterChatModalShow);

  const isAstroCalendarOrigin = modalParams?.place === ASTRO_CALENDAR_PLACE;
  const isAnimationInDisabled = modalParams?.animationDisabled;

  const [isAnimationOutDisabled, setIsAnimationOutDisabled] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [minutesBonus, setMinutesBonus] = useState<number>(0);

  const currentLocale = Localization.getLngCode();
  const browserType = getBrowserType(browserName);
  const isAllowedForPWA = isAllowedBrowserByType(browserType);

  useEffect(() => {
    if (afterChatModalShow && !MODALS_EXCLUDE_ROUTES_IN_CHAT.includes(currentRoute)) {
      dispatch(showModal(MODALS.PWA_INSTRUCTION));
      dispatch(setAfterChatModalShow(false));
      dispatch(setModalShown());
    }
  }, [currentRoute, afterChatModalShow, dispatch]);

  const handleClosePress = () => {
    setIsAnimationOutDisabled(false);
    dispatch(closePwaModal());
  };

  const handleCopyLinkPress = () => {
    Analytics.trackEvent('PWA_Instructions', 'Copied');

    const url = window.location.origin + window.location.pathname;
    const query = queryString.parse(window?.location?.search);
    query.idfm = idfm;
    const link = queryString.stringifyUrl({ url, query });

    Clipboard.setString(String(link));
    setIsCopied(true);
  };

  const checkPromocodeInfo = useCallback(async () => {
    try {
      const promocodeInfo = await getPromocode(promocode);

      if (promocodeInfo) {
        const { isExist, isUsed, seconds } = promocodeInfo;

        if (isExist && !isUsed && seconds) {
          setMinutesBonus(formatMinutes(seconds));
        }
      }
    } catch (error) {
      console.log('> [ERROR] Get PromoCode info error', error);
    }
  }, [promocode]);

  const handleAboutPress = () => {
    setIsAnimationOutDisabled(true);

    dispatch(closeCurrentModal());
    navigate(ROUTES.PWA_ONBOARDING);
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, HIDE_COPIED_TIME);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isCopied]);

  useEffect(() => {
    if (isVisible) {
      const browser = browserType.replace(/IOS_|ANDROID_/, '').toLowerCase();
      Analytics.trackEvent('PWA_Instructions', 'Open', {
        browser,
        type: isPWAAutoInstallAvailable ? 'autoinstall' : 'instruction',
        place: modalParams?.place,
      });
    }
  }, [browserType, isPWAAutoInstallAvailable, isVisible, modalParams?.place]);

  useEffect(() => {
    if (userProfileIsLoaded && promocode) {
      checkPromocodeInfo();
    }
  }, [checkPromocodeInfo, promocode, userProfileIsLoaded]);

  const titleText = useMemo(() => {
    if (!isAllowedForPWA) {
      return t('PWA_INSTRUCTIONS.TITLE_OTHER');
    }

    if (minutesBonus) {
      if (softCurrency.enabled) {
        const amount = convertMinutesToSoftCurrency(minutesBonus, softCurrency.rate);
        return t(`PWA_INSTRUCTIONS.TITLE_FREE_SOFT_CRC`, { amount, icon: `##${softCurrency.type?.toUpperCase()}_ICON##` });
      }
      return t('PWA_INSTRUCTIONS.TITLE_FREE_MINS', { amount: minutesBonus });
    }

    return t('PWA_INSTRUCTIONS.TITLE');
  }, [isAllowedForPWA, minutesBonus, softCurrency]);

  const instructionPointText = useMemo(() => {
    if (softCurrency.enabled) {
      const amount = convertMinutesToSoftCurrency(minutesBonus, softCurrency.rate);
      return t(`PWA_INSTRUCTIONS.INSTRUCTIONS.FREE_SOFT_CRC`, { amount, icon: `##${softCurrency.type?.toUpperCase()}_ICON##` });
    }

    return t(`PWA_INSTRUCTIONS.INSTRUCTIONS.FREE_MINS`, { amount: minutesBonus });
  }, [minutesBonus, softCurrency]);

  const instructions = t(`PWA_INSTRUCTIONS.INSTRUCTIONS.${browserType}`) as unknown as string[];
  const subtitleText = t(isAllowedForPWA ? 'PWA_INSTRUCTIONS.TEXT' : 'PWA_INSTRUCTIONS.TEXT_OTHER');
  const instructionsImage = isAllowedForPWA ? getInstructionGif(browserType, currentLocale ?? 'en') : getInstructionImage(browserType);
  const applySmallStyles = IS_SMALL_SCREEN && isPWAAutoInstallAvailable;

  return (
    <Modal
      style={styles.root}
      isVisible={isVisible}
      animationInTiming={isAnimationInDisabled ? 1 : 500}
      animationOutTiming={isAnimationOutDisabled ? 1 : undefined}
      backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={[styles.wrap, isSafariBrowser(browserName) && styles.wrapMargin]}>
        <View style={styles.topControls}>
          <CloseButtonWithTimeout
            timeout={isAstroCalendarOrigin ? 0 : closeTimeout}
            onPress={handleClosePress}
            source={CLOSE_ICON}
            style={styles.closeButton}
          />
        </View>

        <ScrollView>
          <View style={styles.container}>
            <View style={styles.textWrap}>
              <HighlightText
                font={'Philosopher'}
                textStyle={[styles.title, applySmallStyles && styles.titleSmallScreen]}
                iconWrapStyle={styles.softCurrencyIconWrap}
                iconStyle={styles.softCurrencyIcon}>
                {titleText}
              </HighlightText>
              <Text style={[styles.subtitle, applySmallStyles && styles.subtitleSmallScreen]}>{subtitleText}</Text>
            </View>

            {isAllowedForPWA ? null : (
              <View style={styles.appIconContainer}>
                <Image source={instructionsImage} />
              </View>
            )}

            <View style={styles.instructionsContainer}>
              {isPWAAutoInstallAvailable ? <SaveHome /> : null}

              {instructions.map((text: string, idx: number) => (
                <View style={styles.instructionsItem} key={text}>
                  {text.indexOf('@@CLIPBOARD@@') >= 0 ? (
                    <ClipboardButton style={styles.btn} titleStyle={styles.btnText} onPress={handleCopyLinkPress} />
                  ) : (
                    <>
                      <View style={styles.instructionsItemDotWrap}>
                        {isAllowedForPWA ? (
                          <Text style={[styles.numberText, applySmallStyles && styles.textSmallScreen]}>{`${idx + 1}.`}</Text>
                        ) : (
                          <View style={styles.instructionsItemDot} />
                        )}
                      </View>

                      <HighlightText
                        textStyle={[styles.text, applySmallStyles && styles.textSmallScreen]}
                        highlightTextStyle={[styles.highlightText, applySmallStyles && styles.textSmallScreen]}
                        iconWrapStyle={styles.icon}>
                        {text}
                      </HighlightText>
                    </>
                  )}
                </View>
              ))}
            </View>

            {isAllowedForPWA ? (
              <View style={styles.appIconContainer}>
                <Image style={[styles.gifImage, applySmallStyles && styles.gifImageSmallScreen]} source={instructionsImage} />
              </View>
            ) : null}

            {minutesBonus ? (
              <View style={styles.instructionsContainer}>
                <View style={styles.instructionsItem}>
                  <View style={styles.instructionsItemDotWrap}>
                    <Text style={[styles.numberText, applySmallStyles && styles.textSmallScreen]}>{'3.'}</Text>
                  </View>

                  <HighlightText
                    textStyle={[styles.text, applySmallStyles && styles.textSmallScreen]}
                    highlightTextStyle={[styles.highlightText, applySmallStyles && styles.textSmallScreen]}
                    iconStyle={styles.softCurrencyPointIcon}>
                    {instructionPointText}
                  </HighlightText>
                </View>
              </View>
            ) : null}
          </View>
        </ScrollView>

        {showAboutBtn && (
          <Button
            title={t('PWA_INSTRUCTIONS.ABOUT_BTN')}
            style={styles.aboutBtn}
            titleStyle={styles.aboutBtnTitle}
            fontFamily={'Philosopher'}
            isTransparent
            onPress={handleAboutPress}
          />
        )}
      </View>

      <ClipBoardTooltip isVisible={isCopied} />
    </Modal>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  wrap: {
    width: '100%',
    paddingBottom: BOTTOM_OFFSET + paddingVertical(15),
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    alignItems: 'center',
    position: 'relative',
    maxHeight: '97%',
  },
  wrapMargin: {
    paddingBottom: BOTTOM_OFFSET + paddingVertical(15),
  },
  container: {
    width: '100%',
    paddingHorizontal: paddingHorizontal(15),
    alignItems: 'center',
    paddingBottom: paddingVertical(25),
  },
  topControls: {
    paddingTop: paddingVertical(10),
    alignItems: 'flex-end',
    width: '100%',
  },
  closeButton: {
    padding: paddingHorizontal(20),
  },
  title: {
    fontSize: fs(29),
    lineHeight: fs(35),
    fontWeight: '700',
    textAlign: 'center',
    color: COLORS.GOLDEN,
  },
  titleSmallScreen: {
    fontSize: fs(24),
    lineHeight: fs(28),
  },
  textWrap: {
    width: '100%',
    marginBottom: paddingVertical(5),
  },
  subtitle: {
    textAlign: 'center',
    fontSize: fs(16),
    lineHeight: fs(24),
    color: COLORS.GOLDEN,
    marginTop: paddingVertical(10),
  },
  subtitleSmallScreen: {
    fontSize: fs(14),
    lineHeight: fs(20),
  },
  appIconContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: paddingVertical(20),
  },
  gifImage: {
    height: sw(120),
    width: sw(282),
  },
  gifImageSmallScreen: {
    height: sw(100),
    width: sw(235),
  },
  instructionsContainer: {
    width: '100%',
    justifyContent: 'space-between',
    paddingHorizontal: paddingHorizontal(15),
  },
  instructionsItem: {
    flex: 1,
    marginTop: paddingVertical(15),
    paddingLeft: paddingHorizontal(30),
    position: 'relative',
  },
  btn: {
    marginLeft: paddingHorizontal(-30),
    alignSelf: 'center',
  },
  btnText: {
    textAlign: 'center',
    fontSize: fs(15),
    fontWeight: '600',
    color: COLORS.WHITE,
  },
  text: {
    fontSize: fs(16),
    lineHeight: fs(24),
    fontWeight: '400',
    color: color(COLORS.BEIGE, 0.8),
  },
  textSmallScreen: {
    fontSize: fs(14),
    lineHeight: fs(20),
  },
  highlightText: {
    fontSize: fs(16),
    lineHeight: fs(24),
    fontWeight: '600',
    color: COLORS.BEIGE,
  },
  icon: {
    width: 34,
  },
  softCurrencyIconWrap: {
    width: sw(25),
  },
  softCurrencyIcon: {
    width: sw(25),
    top: -21,
  },
  softCurrencyPointIcon: {
    width: sw(20),
    top: -17,
  },
  instructionsItemDotWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: 24,
    left: 0,
  },
  instructionsItemDot: {
    width: sw(8),
    height: sw(8),
    borderRadius: sw(4),
    backgroundColor: COLORS.AQUA,
  },
  numberText: {
    fontSize: fs(16),
    lineHeight: fs(24),
    fontWeight: '700',
    color: COLORS.AQUA,
  },
  aboutBtn: {
    minHeight: sw(50),
    maxWidth: sw(325),
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: COLORS.AQUA,
    backgroundColor: color(COLORS.AQUA, 0.1),
    marginTop: 10,
  },
  aboutBtnTitle: {
    color: COLORS.WHITE,
    fontSize: fs(20),
    textAlign: 'center',
    fontWeight: '700',
  },
});

export default memo(PWAInstructions);
