import { createAction } from 'redux-actions';
import _, { isEmpty } from 'lodash';

import { AppDispatch, AppGetState } from 'store';
import Analytics from 'analytics';
import { getAstrologersConsultationsPurchases } from 'store/astrologers/monetization/selectors';
import { navigate } from 'store/navigation/actions';
import { ADVISORS_CONNECTION, ASTROEVENT_EXCLUDE_ROUTES } from 'constants/routes';
import { showModal, closeCurrentModal } from 'store/modals/actions';
import * as MODALS from 'constants/modals';
import * as TRIGGERS from 'constants/monetization-triggers';
import { navigateWithMonetization } from 'store/unlock-content/actions';

import { TEMPLATE_IDS, ASTROLOGERS_OPEN_PLACE } from '../../../screens/advisors/constants';
import {
  getLastConnectedAstrologer,
  selectAdvisorForCrossPromo,
  selectIsPremiumWithoutForcedOnlineAdvisor,
  selectOnlineAdvisors,
} from '../selectors';
import { addPendingChat, setChatMessages } from '../chat/actions';
import { accrueFreeTime } from '../time/actions';
import { setCompleted } from '../onboarding/actions';

import { TYPES } from './types';

export const setAstroeventCounters = createAction(TYPES.SET_ASTROEVENT_COUNTERS);
const setFreeConsultationShowed = createAction(TYPES.SET_FREE_CONSULTATION_SHOWED);
const setAstroeventModalShowed = createAction(TYPES.SET_ASTROEVENT_MODAL_SHOWED);

export const startAstroeventChat = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const availableAstrologist = selectAdvisorForCrossPromo(state);
    const lastAstrologist = getLastConnectedAstrologer(state);
    const consultant = lastAstrologist ? lastAstrologist : availableAstrologist;
    const chatId = consultant.astrologer_id;
    const isChatHistoryExist = state.astrologers.chat.chats?.[chatId]?.messages?.length;
    const activeModal = state.modals.activeModal;
    const isPremium = selectIsPremiumWithoutForcedOnlineAdvisor(state, consultant);

    dispatch(setCompleted());

    const route = ADVISORS_CONNECTION;
    const params = {
      id: consultant.astrologer_id,
      needResponse: 0,
      templateId: TEMPLATE_IDS.ASTROEVENT,
      place: ASTROLOGERS_OPEN_PLACE.ASTROEVENT,
    };

    const handleAfterNavigate = () => {
      if (!isChatHistoryExist) {
        dispatch(setChatMessages({ chatId, messages: [] }));
      }

      dispatch(addPendingChat(`${chatId}`));
    };

    if (isPremium) {
      dispatch(
        navigateWithMonetization({
          trigger: TRIGGERS.PREMIUM_ADVISOR_START_CHAT,
          route,
          params,
          onSuccess: () => {
            handleAfterNavigate();
          },
        }),
      );
    } else {
      navigate(route, params);
      handleAfterNavigate();
    }

    if (activeModal === MODALS.ADVISOR_ASTRO_EVENT) {
      dispatch(closeCurrentModal());
    }
  };
};

export const closeAstroeventModal = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        modals: {
          astroeventCounters: { withConnections, withoutConnections },
        },
        chat: { chats },
      },
    } = getState();

    const isChatsEmpty = _.isEmpty(chats);
    const payload = isChatsEmpty ? { withoutConnections: withoutConnections + 1 } : { withConnections: withConnections + 1 };

    dispatch(setAstroeventCounters(payload));
    dispatch(closeCurrentModal());
  };
};

export const showAstroeventModal = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      astrologers: {
        modals: { astroeventCounters, isAstroeventModalShowed },
        core: { lastConnectionSessionNumber },
        chat: { chats },
      },
    } = state;
    const astroeventModalConfig = state.remoteConfig.astroeventModalConfig;
    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;

    const onlineAdvisors = selectOnlineAdvisors(state);

    if (isEmpty(onlineAdvisors)) {
      return false;
    }

    if (!astroeventModalConfig?.enable || isAstroeventModalShowed) {
      return false;
    }

    const isChatsEmpty = _.isEmpty(chats);

    if (isChatsEmpty) {
      if (currentSession < astroeventModalConfig.noChatShowSession) {
        return false;
      }

      if (astroeventCounters.withoutConnections >= astroeventModalConfig.noChatCloseRepeat) {
        return false;
      }
    } else {
      if (currentSession - astroeventModalConfig.hasChatShowSession < lastConnectionSessionNumber) {
        return false;
      }

      if (astroeventCounters.resetCount >= astroeventModalConfig.hasChatResetShow) {
        return false;
      }

      if (astroeventCounters.withConnections >= astroeventModalConfig.hasChatCloseRepeat) {
        return false;
      }
    }

    setTimeout(() => {
      const currentRoute = getState().navigation.currentRoute;
      if (!ASTROEVENT_EXCLUDE_ROUTES.includes(currentRoute)) {
        dispatch(showModal(MODALS.ADVISOR_ASTRO_EVENT));
        dispatch(setAstroeventModalShowed(true));
      }
    }, astroeventModalConfig?.timeout * 1000);
    return true;
  };
};

export const rewardForRenewHandler = (needsToReward = false) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      astrologers: {
        chat: { chats },
        modals: { freeConsultationShowed },
      },
    } = state;

    const freeMinutes = state?.remoteConfig.astrologerFreeMinutesForRenew;
    const session = state?.remoteConfig.astrologerFreeMinutesModalSession;
    const astrologersConsultationsPurchases = getAstrologersConsultationsPurchases(state);

    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;

    if (needsToReward && freeMinutes > 0) {
      dispatch(showModal(MODALS.FREE_CONSULTATION));
      await dispatch(accrueFreeTime({ time: freeMinutes * 60, type: 'cycle', increaseFreeBalance: true }));
      return true;
    }

    if (freeConsultationShowed) {
      return false;
    }

    if (Object.values(chats).length) {
      return false;
    }

    if (astrologersConsultationsPurchases.length) {
      return false;
    }

    if (currentSession !== session) {
      return false;
    }

    dispatch(setFreeConsultationShowed(true));
    dispatch(showModal(MODALS.FREE_CONSULTATION));
    return true;
  };
};

export const showAutoRefillModal = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        monetization: { renewData },
      },
    } = getState();

    if (!renewData) {
      return false;
    }

    dispatch(showModal(MODALS.AUTO_REFILL));

    return true;
  };
};
