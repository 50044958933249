import { Linking, Platform } from 'react-native';
import DeviceProps from '@magnus/react-native-device-props';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { AppDispatch, AppGetState } from 'store';
import {
  createOneClickPayment,
  getPaymentMethod,
  getWebSubs,
  getWebOneTimePurchases,
  getWebAllSubs,
  getProducts,
  createSubscriptionByOneClick,
} from 'api/purchases';
import {
  OneClickPaymentData,
  OnetimePaymentResponse,
  WebPurchase,
  PaymentData,
  SubscriptionPaymentResponse,
  SubscriptionPaymentData,
} from 'api/purchases/interfaces';
import { SUB_CANCEL_LINK, getWebSubCancelLink } from 'constants/general';
import { addTriggerNotification } from 'store/astrologers/notifications/actions';
import { WEB_SUBSCRIPTION_STATUS } from 'modules/payments/constants';
import { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components';
import { mapWebProduct, mapWebSubscription } from 'modules/payments/utils';
import { AVAILABLE_SUBSCRIPTION_PAGES } from 'constants/subscription';

import { setupPurchasedState } from './actions';
import { BILLING_TYPES } from './types';
import { getPrevCurrency } from './selectors';

const setWebPaymentMethod = createAction(BILLING_TYPES.SET_WEB_PAYMENT_METHOD);
const setWebTransactions = createAction(BILLING_TYPES.SET_WEB_TRANSACTIONS);
const setWebOneTimePurchases = createAction(BILLING_TYPES.SET_WEB_ONE_TIME_PURCHASES);
const setWebProducts = createAction(BILLING_TYPES.SET_WEB_PRODUCTS);
const setWebAllSubscriptions = createAction(BILLING_TYPES.SET_WEB_ALL_SUBSCRIPTIONS);
const setRedemptionSubscription = createAction(BILLING_TYPES.SET_WEB_REDEMPTION_SUBSCRIPTION);

export const trackEvent = (event: string, params?: any) => Analytics.trackEvent('billing', event, params);

export const cancelSubscription = () => {
  return async (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { webUUID },
      billing: { webTransactions },
    } = getState();

    const webSubEmail = webTransactions?.find(sub => sub?.customer_email)?.customer_email || '';

    const idfm = webUUID ? webUUID : await DeviceProps.getIDFM();
    const link = webSubEmail ? `${getWebSubCancelLink()}${encodeURIComponent(webSubEmail)}&idfm=${idfm}&platform=${Platform.OS}` : SUB_CANCEL_LINK;
    Linking.openURL(link || '');
  };
};

export const fetchPaymentMethod = () => {
  return async (dispatch: AppDispatch) => {
    const paymentMethod = await getPaymentMethod();
    return dispatch(setWebPaymentMethod(paymentMethod));
  };
};

export const payByOneClick = ({
  data,
  onSuccess,
  onError,
}: {
  data: OneClickPaymentData;
  onSuccess?: (res: OnetimePaymentResponse['one_time_payment']) => void;
  onError?: (error: any) => void;
}) => {
  return async (_dispatch: AppDispatch, _getState: AppGetState) => {
    try {
      const response = await createOneClickPayment(data);

      if (typeof onSuccess === 'function') {
        onSuccess(response.one_time_payment);
      }

      return response;
    } catch (error) {
      console.log('[ERROR PAY BY ONE CLICK]: ', error);
      if (typeof onError === 'function') {
        onError(error);
      }
    }
  };
};

export const verifyWebSubscriptions = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: { paymentFailedTriggerNotificationEnabled },
      billing: { redemptionSubscription },
    } = getState();

    const allSubscriptions = await getWebAllSubs();
    const products = await dispatch(fetchWebProducts(allSubscriptions));
    const subscriptions = allSubscriptions.map((p: WebPurchase) => mapWebSubscription(p, products));
    dispatch(setWebAllSubscriptions(subscriptions));

    const redemption = subscriptions.find(s => s.status === WEB_SUBSCRIPTION_STATUS.REDEMPTION);

    if (redemption) {
      Analytics.track('Subscription_Redemption_In');

      dispatch(setRedemptionSubscription(redemption));

      if (paymentFailedTriggerNotificationEnabled) {
        Analytics.trackEvent('TopNotification', 'Show', {
          type: EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED,
        });

        dispatch(addTriggerNotification({ type: EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED }));
      }
    } else if (redemptionSubscription) {
      Analytics.track('Subscription_Redemption_Out');

      dispatch(setRedemptionSubscription(null));
    }

    // IT NEEDS BECAUSE ACTUALIZED REQUEST UPDATES ACTUALLY STATES OF PURCHASES
    await dispatch(fetchWebPurchases());

    return subscriptions;
  };
};

export const fetchWebPurchases = () => {
  return async (dispatch: AppDispatch) => {
    const [transactions, oneTimePurchases] = await Promise.all([getWebSubs(), getWebOneTimePurchases()]);

    dispatch(setWebTransactions(transactions));
    dispatch(setWebOneTimePurchases(oneTimePurchases));
    dispatch(fetchPaymentMethod());
    dispatch(setupPurchasedState());

    return true;
  };
};

const fetchWebProducts = (subscriptions: WebPurchase[]) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { remoteConfig } = state;
    const userCurrency = getPrevCurrency(state);

    const ids: string[] = [];

    AVAILABLE_SUBSCRIPTION_PAGES.forEach(sub => {
      const subscriptionPageData = remoteConfig[sub];
      if (subscriptionPageData && subscriptionPageData.productId) {
        ids.push(subscriptionPageData.productId);

        const subIdByUserCurrency = subscriptionPageData.productsByCurrency?.[userCurrency];
        if (subIdByUserCurrency) {
          ids.push(subIdByUserCurrency);
        }
      }
    });

    if (subscriptions.length) {
      subscriptions.map(i => {
        if (!ids.includes(i.product)) {
          return ids.push(i.product);
        }
      });
    }

    const products = await getProducts(ids);
    const preparedProducts = products.map(mapWebProduct);
    dispatch(setWebProducts(preparedProducts));
    return products;
  };
};

export const subscribeByOneClick = ({
  data,
  onSuccess,
  onError,
}: {
  data: PaymentData;
  onSuccess?: (res: SubscriptionPaymentResponse['data']) => void;
  onError?: (error: any) => void;
}) => {
  return async (_dispatch: AppDispatch, _getState: AppGetState) => {
    try {
      const response = await createSubscriptionByOneClick({
        productId: data.productCode,
        metadata: data.metadata,
        description: data.description,
        trigger: data.trigger,
        solidMetadata: data.solidMetadata,
      } as SubscriptionPaymentData);

      if (typeof onSuccess === 'function') {
        onSuccess(response.data);
      }

      return response;
    } catch (error) {
      console.log('[ERROR SUBSCRIPTION PAY BY ONE CLICK]: ', error);
      if (typeof onError === 'function') {
        onError(error);
      }
    }
  };
};
