import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useCallback, Suspense } from 'react';
import { Platform } from 'react-native';
import { useDispatch } from 'react-redux';

import { TRANSPARENT } from 'constants/colors';
import { DEFAULT_OPTIONS } from 'constants/navigation';
import useAppStateChange from 'hooks/use-app-state-change';
import useEventListeners from 'hooks/use-event-listeners';
import useSiriShortcut from 'hooks/use-siri-shortcut';
import { navigationRef, navigationChangeCallback } from 'store/navigation/actions';
import LoaderOverlay from 'components/loader/overlay';
import * as ROUTES from 'constants/routes';
import type { AppDispatch } from 'store';
import TriggerNotification from 'components/trigger-notification';

import SCREENS from './screens';

const SCREENS_WITHOUT_ANIMATION = [
  ROUTES.TAROT_CARD_OF_THE_DAY,
  ROUTES.TAROT_YES_OR_NO,
  ROUTES.TAROT_LAYOUT,
  ROUTES.SUBSCRIPTION_SUB_BENEFITS_WITHOUT_ANIM,
  ROUTES.SUBSCRIPTION_BENEFITS_OFFER_WITHOUT_ANIM,
  ROUTES.ADVISORS_CHAT_WITHOUT_ANIM,
];

const Stack = createStackNavigator();

const navigatorTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: TRANSPARENT,
  },
};

const Navigator = () => {
  useAppStateChange();
  useEventListeners();
  useSiriShortcut();
  const dispatch = useDispatch<AppDispatch>();
  const handleChange = useCallback(() => dispatch(navigationChangeCallback()), []);

  const renderScreen = ([key, val]) => {
    return (
      <Stack.Screen
        options={{
          animationEnabled: !SCREENS_WITHOUT_ANIMATION.includes(key),
        }}
        key={key}
        name={key}
        component={val.component ?? val}
        {...val.params}
      />
    );
  };

  const renderNavigation = () => (
    <>
      <TriggerNotification />

      <NavigationContainer ref={navigationRef} onStateChange={handleChange} theme={navigatorTheme}>
        <Stack.Navigator screenOptions={DEFAULT_OPTIONS}>{Object.entries(SCREENS).map(renderScreen)}</Stack.Navigator>
      </NavigationContainer>
    </>
  );

  if (Platform.OS === 'web') {
    return <Suspense fallback={<LoaderOverlay visible />}>{renderNavigation()}</Suspense>;
  }

  return renderNavigation();
};

export default Navigator;
